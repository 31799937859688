<template>
  <v-container fluid :class="{'pa-0': sm}">
    <v-row dense v-if="ads.length > 0">
      <BidStream></BidStream>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-toolbar color="color1 color1Text--text" class="mx-auto" :dark="color1IsDark">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              :slot="sm ? 'extension' : null"
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              clearable
              :class="{'mb-3': sm}"
            ></v-text-field>
            <v-btn color="color1Text" text icon @click.stop="userFilter = !userFilter" v-if="false">
              <v-icon>fas fa-filter</v-icon>
            </v-btn>
          </v-toolbar>
          <v-tabs
            v-model="activeTab"
            fixed-tabs
            background-color="color2"
            slider-color="color3"
            slider-size="3"
            active-class="color3 color3Text--text"
          >
            <v-tab
              v-for="(tab) in tabs"
              :key="tab.to"
              :to="tab.to"
              class="color2 color2Text--text"
              active-class="color3 color3Text--text"
            >
              <v-badge right color="red" v-if="tab.n">
                <span slot="badge">{{tab.n}}</span> <!--slot can be any component-->
                <span>{{tab.text}}</span>
              </v-badge>

              <span v-else >{{tab.text}}</span>
            </v-tab>
          </v-tabs>
          <loading-bar :value="loading"></loading-bar>
          <div v-if="filterBtns" class="justify-center d-flex py-2">
            <v-btn
              v-for="(btn, i) in filterBtns"
              :key="i"
              :color="btn.active ? 'color3 color3Text--text' : 'color3Text color3--text'"
              :class="{ 'ml-2': i > 0 }"
              @click.stop="onFilterBtnClick(btn, i)"
            >
              {{ btn.text }}
            </v-btn>
          </div>
          <!-- FILTER -->
          <v-expand-transition>
            <v-container v-if="!sm && newFilter" class="py-0" fluid>
              <v-row dense class="justify-center">
                <!-- Location FILTER -->
                <v-col cols="6" md="3">
                  <v-card class="fill-height">
                    <v-toolbar color="color2 color2Text--text" dense>
                      Location
                    </v-toolbar>
                    <v-card-text>
                      <v-switch
                        label="Use Current Location"
                        v-model="myFilter.useCurrent"
                        :loading="loading"
                        color="success"
                        :error-messages="gError"
                      >
                        <template v-slot:label>
                          Use Current Location <help-video-dialog v-if="gError" videoId="yKPfdDV_5_U"></help-video-dialog>
                        </template>
                      </v-switch>
                      <v-expand-transition>
                        <v-form @submit.prevent="searchLocation" v-if="!myFilter.useCurrent">
                          <v-text-field
                            label="City, ST or Postal Code"
                            v-model="location"
                            :loading="gettingLocation"
                            hide-details
                          >
                            <template v-slot:append-outer>
                              <v-btn
                                color="color3 color3Text--text"
                                small
                                :disabled="disabledSearch"
                                :loading="gettingLocation"
                                @click.stop="searchLocation"
                              >Search</v-btn>
                            </template>
                          </v-text-field>
                        </v-form>
                      </v-expand-transition>
                      <v-expand-transition>
                        <div v-if="geoPosition || location">
                          <v-select
                            ref="miles"
                            :items="[
                              {text: 'Any Distance', value: 999999},
                              {text: '50 Miles', value: 50},
                              {text: '100 Miles', value: 100},
                              {text: '250 Miles', value: 250},
                              {text: '500 Miles', value: 500}
                            ]"
                            v-model="myFilter.maxDistance"
                            label="Distance"
                            color="color3"
                            item-color="color3"
                          ></v-select>
                        </div>
                      </v-expand-transition>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- Event Type FILTER -->
                <v-col cols="6" md="3">
                  <v-card class="fill-height">
                    <v-toolbar color="color2 color2Text--text" dense>
                      Event Type
                    </v-toolbar>
                    <v-card-text>
                      <v-checkbox
                        v-for="e in eventTypes"
                        :key="e"
                        :label="e"
                        v-model="myFilter.eventTypes"
                        :value="e"
                        color="success"
                        hide-details class="mt-0"
                      ></v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- SB FILTER -->
                <v-col cols="6" md="3" v-if="!sb">
                  <v-card class="fill-height">
                    <v-toolbar color="color2 color2Text--text" dense>
                      Sanctioning Body
                    </v-toolbar>
                    <v-card-text>
                      <v-checkbox
                        v-for="e in sBodies"
                        :key="e"
                        :label="e"
                        v-model="myFilter.sBodies"
                        :value="e"
                        color="success"
                        hide-details class="mt-0"
                      ></v-checkbox>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- Other FILTER -->
                <v-col cols="6" md="3">
                  <v-card class="fill-height">
                    <v-toolbar color="color2 color2Text--text" dense>
                      Other Filters
                    </v-toolbar>
                    <v-card-text>
                      <v-checkbox
                        v-for="e in others"
                        :key="e.value"
                        :label="e.text"
                        v-model="myFilter.others"
                        :value="e.value"
                        color="success"
                        hide-details class="mt-0"
                      ></v-checkbox>
                      <div v-if="user && user.vbl">
                        Date Range
                        <v-select
                          :items="[2020,2021,2022,2023]"
                          v-model="year"
                          label="Year" clearable
                        ></v-select>
                        <v-select
                          :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
                          v-model="month"
                          label="Month" clearable
                        ></v-select>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-expand-transition>
          <v-expand-transition>
            <v-row dense>
              <BidStream v-if="ads.length === 0"></BidStream>
              <v-col cols="12" class="d-flex justify-center" v-if="rowHeaderAds.length > 0">
                <div class="mr-2" style="height: 150px; width: 395px;" v-for="ad in rowHeaderAds" :key="ad.id">
                  <ad-card :ad="ad" :flat="true" :rowHeader="true"></ad-card>
                </div>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-tabs-items v-model="activeTab">
            <v-tab-item
              v-for="(tab) in tabs"
              :key="tab.to"
              :value="tab.to"
            >
              <router-view
                v-if="activeTab === tab.to"
                :filter="filter"
                :tournaments="filteredList"
                :ads="filteredAds"
                :loading="loading"
                :search="search"
                :key="$route.params.filter"
                :listView="listView"
                :filterItems="myFilter.others"
                :sortDesc="filter === 'previous' || filter === 'today'"
                :lastPage="lastPage"
                @page-update="onPageChange"
              ></router-view>
            </v-tab-item>
          </v-tabs-items>
          <!-- CARD/LIST FAB-->
          <v-tooltip left>
            <span>{{listView ? 'Card View' : 'List View'}}</span>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="color3 color3Text--text"
                fab fixed bottom right
                @click.stop="toggleView"
              >
                <v-icon v-if="listView">fas fa-table</v-icon>
                <v-icon v-else>fas fa-list</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <!-- Filter FAB-->
          <v-fab-transition>
            <v-tooltip right v-if="sm && newFilter">
              <span>Filter</span>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="color3 color3Text--text"
                  fab fixed bottom right
                  :style="`margin-right: 60px`"
                  @click.stop="dialog = true"
                >
                  <v-icon>fas fa-filter</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </v-fab-transition>
        </v-card>
      </v-col>
      <v-col class="text-right" cols="12" >
        <v-btn color="color3" text icon @click.stop="getList">
          <v-icon>fas fa-sync-alt</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Filter DIALOG-->
    <v-dialog
      v-model="dialog"
      fullscreen scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="success whiteText--text"
            small fab
            @click.stop="dialog = false"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-0">
          <v-container v-if="userFilter" class="py-0 px-2" fluid>
            <v-row dense v-if="newFilter">
              <!-- Location FILTER -->
              <v-col cols="12" sm="6">
                <v-card class="fill-height">
                  <v-toolbar color="color2 color2Text--text" dense>
                    Location
                  </v-toolbar>
                  <v-card-text>
                    <v-switch
                      label="Use Current Location"
                      v-model="myFilter.useCurrent"
                      :loading="loading"
                      color="success"
                      hide-details
                    ></v-switch>
                    <v-expand-transition>
                      <v-form @submit.prevent="searchLocation" v-if="!myFilter.useCurrent">
                        <v-text-field
                          label="City, ST or Postal Code"
                          v-model="location"
                          :loading="gettingLocation"
                          hide-details
                        >
                          <template v-slot:append-outer>
                            <v-btn
                              color="color3 color3Text--text"
                              small
                              :disabled="disabledSearch"
                              :loading="gettingLocation"
                              @click.stop="searchLocation"
                            >Search</v-btn>
                          </template>
                        </v-text-field>
                      </v-form>
                    </v-expand-transition>
                    <v-expand-transition>
                      <div v-if="geoPosition || location">
                        <v-select
                          ref="miles"
                          :items="[
                            {text: 'Any Distance', value: 999999},
                            {text: '50 Miles', value: 50},
                            {text: '100 Miles', value: 100},
                            {text: '250 Miles', value: 250},
                            {text: '500 Miles', value: 500}
                          ]"
                          v-model="myFilter.maxDistance"
                          label="Distance"
                          color="color3"
                          item-color="color3"
                        ></v-select>
                      </div>
                    </v-expand-transition>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Event Type FILTER -->
              <v-col cols="12" sm="6">
                <v-card class="fill-height">
                  <v-toolbar color="color2 color2Text--text" dense>
                    Event Type
                  </v-toolbar>
                  <v-card-text>
                    <v-checkbox
                      v-for="e in eventTypes"
                      :key="e"
                      :label="e"
                      v-model="myFilter.eventTypes"
                      :value="e"
                      color="success"
                      hide-details class="mt-0"
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- SB FILTER -->
              <v-col cols="12" sm="6" v-if="!sb">
                <v-card class="fill-height">
                  <v-toolbar color="color2 color2Text--text" dense>
                    Sanctioning Body
                  </v-toolbar>
                  <v-card-text>
                    <v-checkbox
                      v-for="e in sBodies"
                      :key="e"
                      :label="e"
                      v-model="myFilter.sBodies"
                      :value="e"
                      color="success"
                      hide-details class="mt-0"
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Other FILTER -->
              <v-col cols="12" sm="6">
                <v-card class="fill-height">
                  <v-toolbar color="color2 color2Text--text" dense>
                    Other Filters
                  </v-toolbar>
                  <v-card-text>
                    <v-checkbox
                      v-for="e in others"
                      :key="e.value"
                      :label="e.text"
                      v-model="myFilter.others"
                      :value="e.value"
                      color="success"
                      hide-details class="mt-0"
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-scale-transition>
      <v-overlay :value="overlay" opacity=".8" color="white">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" class="title text-center color3--text"><h2>What are you looking for?</h2></v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-card class="fill-height clickable" @click.native="goto(1)" color="color3 color3Text--text">
                <v-card-text class="d-flex align-center justify-center fill-height text-center">
                  <h2>Tournaments & Leagues</h2>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="fill-height clickable" @click.native="goto(2)" color="color3 color3Text--text">
                <v-card-text class="d-flex align-center justify-center fill-height text-center">
                  <h2>Camps & Clinics</h2>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-card class="fill-height clickable"  @click.native="goto(3)" color="color3 color3Text--text">
                <v-card-text class="d-flex align-center justify-center">
                  <h3>Show Me Everything</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-overlay>
    </v-scale-transition>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Summary from '@/classes/TournamentSummary'
import * as StatusEnum from '@/classes/TournamentStatus'
import flatten from '@/helpers/ArrayFlatten'
import * as mutations from '@/store/MutationTypes'
import debounce from 'lodash.debounce'
import uniq from 'lodash.uniq'
import HelpVideoDialog from '@/components/Utils/HelpVideoDialog'
import AdCard from '@/components/Ads/AdCard.vue'
// import GoogleAdsense from '@/components/Ads/GoogleAdsense.vue'

export default {
  props: ['username', 'filter'],
  data () {
    return {
      loading: false,
      activeTab: null,
      summaries: [],
      ads: [],
      search: null,
      listView: false,
      geoPosition: null,
      userFilter: true,
      filterItems: [],
      lastPage: null,
      eventTypes: ['Tournament', 'League', 'Camp', 'Clinic', 'Series', 'Tryout', 'Program'],
      sBodies: ['AAU', 'AVP', 'BVCA', 'BVNE', 'JVA', 'p1440', 'USAV', 'Unsanctioned'],
      baseFilters: ['Tournament', 'Camp', 'Clinic', 'Series', 'League'],
      others: [
        { text: 'Has Adult Divisions', value: 'Adult' },
        { text: 'Has Juniors Divisions', value: 'Juniors' },
        { text: 'National Championship', value: 'National' },
        { text: 'Is A Showcase', value: 'Showcase' }
      ],
      gettingLocation: false,
      location: null,
      gError: null,
      myFilter: {
        eventTypes: ['Tournament', 'Series', 'League'],
        sBodies: ['AAU', 'AVP', 'BVCA', 'BVNE', 'JVA', 'p1440', 'USAV', 'Unsanctioned'],
        others: [],
        useCurrent: true,
        searchAddress: null,
        searchLatLong: null,
        maxDistance: 999999
      },
      page: null,
      dialog: false,
      overlay: false,
      overlayShown: false,
      currentUrl: null,
      filterBtns: null,
      year: null,
      month: null
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'organizationName',
      'color1IsDark',
      'publicView',
      'liveUpdates',
      'liveUpdateStatus',
      'preferences',
      'subdomain',
      'theme',
      'eventsTab',
      'bidStream'
    ]),
    noAds () {
      return this.theme.props.includes('noAds')
    },
    newFilter () {
      return ['vbl', 'bvne', 'p1440'].includes(this.theme.username) || this.theme.props.includes('filter')
    },
    sb () {
      return ['bvne', 'p1440', 'aau', 'usav'].includes(this.theme.username)
    },
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    sm () {
      return this.$vuetify.breakpoint.smAndDown
    },
    availableTags () {
      const ids = this.summaries.map(m => `${m.id}`)
      const base = [...new Set(flatten(this.summaries.map(m => m.tags)))].filter(f => !ids.includes(f)).sort()
      return uniq([...base, ...this.baseFilters]).sort()
    },
    q () {
      const s = this.currentUrl && this.currentUrl.split('?')
      return s && s.length > 1 ? `?${s[1]}` : false
    },
    tabs () {
      if (this.eventsTab === 'current') {
        return [
          { text: 'Current & Upcoming', to: '/tournaments/current' },
          { text: 'Previous', to: '/tournaments/previous' }
        ]
      }
      return [
        { text: 'Ongoing', to: '/tournaments/ongoing', n: this.ongoingTournaments ? this.ongoingTournaments.length : 0 },
        { text: 'Today\'s', to: '/tournaments/today', n: this.runningTournaments ? this.runningTournaments.length : 0 },
        { text: 'Upcoming', to: '/tournaments/upcoming' },
        { text: 'Previous', to: '/tournaments/previous' }
      ]
    },
    title () {
      return this.organizationName ? `${this.organizationName} Events` : 'Events'
    },
    filteredList () {
      let base = []
      switch (this.$route.params.filter) {
        case 'ongoing':
          base = this.ongoingTournaments
          break
        case 'today':
          base = this.runningTournaments
          break
        case 'previous':
          base = this.pastTournaments
          break
        case 'current':
          base = [...this.ongoingTournaments, ...this.runningTournaments, ...this.upcomingTournaments]
          break
        default:
          base = this.upcomingTournaments
      }
      if (this.eventTypes.length !== this.myFilter.eventTypes.length) {
        base = base.filter(f => this.myFilter.eventTypes.includes(f.eType))
      }
      if (this.sBodies.length !== this.myFilter.sBodies.length) {
        // base = base.filter(f => this.myFilter.sBodies.includes(f.sBody))
        base = base.filter(f => this.myFilter.sBodies.some(x => f.allSanctionBodies.includes(x)))
      }
      if (this.myFilter.maxDistance && this.myFilter.maxDistance < 99999 && this.latLong) {
        base = base.filter(f => f.isWithin(this.myFilter.maxDistance, this.latLong))
      }
      if (this.year) {
        base = base.filter(f => f.startDate.year() === this.year)
      }
      if (this.month) {
        base = base.filter(f => f.startDate.month() === this.month - 1)
      }
      return base
    },
    pastTournaments () {
      return this.filterList(StatusEnum.PAST)
    },
    runningTournaments () {
      return this.filterList(StatusEnum.INPROCESS)
    },
    ongoingTournaments () {
      return this.filterList(StatusEnum.ONGOING)
    },
    upcomingTournaments () {
      return this.filterList(StatusEnum.UPCOMING)
    },
    xsClass () {
      return {
        'text-h3': this.$vuetify.breakpoint.xs,
        'text-h1': !this.$vuetify.breakpoint.xs
      }
    },
    latLong () {
      if (this.myFilter.useCurrent) {
        return this.geoPosition && `${this.geoPosition.coords.latitude} ${this.geoPosition.coords.longitude}`
      }
      return this.myFilter.searchLatLong
    },
    disabledSearch () {
      return this.location === this.searchLocation
    },
    filterDto () {
      const obj = {}
      const e = this.eventTypes.filter(f => !this.myFilter.eventTypes.includes(f))
      obj.xE = e.join(',')
      const sb = this.sBodies.filter(f => !this.myFilter.sBodies.includes(f))
      obj.xSb = sb.join(',')
      obj.o = [...this.myFilter.others].join(',')
      if (!this.myFilter.useCurrent) obj.u = this.myFilter.useCurrent
      if (this.myFilter.searchAddress) obj.sa = this.myFilter.searchAddress
      if (this.myFilter.searchLatLong) obj.sll = this.myFilter.searchLatLong
      obj.u = this.myFilter.useCurrent ? '' : 'false'
      obj.d = this.myFilter.maxDistance < 99999 ? `${this.myFilter.maxDistance}` : ''

      // if (!obj.Keys().length) return false
      return obj
    },
    filteredAds () {
      return this.listView ? this.ads.filter(f => !f.noRow) : this.ads
    },
    rowHeaderAds () {
      return this.listView && this.ads.filter(f => f.rowHeader)
    }
  },
  methods: {
    onFilterBtnClick (btn, i) {
      if (btn.active) return
      const exFilter = btn.query.xE ? btn.query.xE.split(',') : false
      if (exFilter) {
        const events = [...this.eventTypes].filter(f => !exFilter.includes(f))
        this.myFilter.eventTypes = events
        this.filterBtns.forEach((f, fi) => {
          f.active = fi === i
        })
      }
    },
    mapFilterBtns () {
      const jProps = this.theme.jsonProps ? JSON.parse(this.theme.jsonProps) : false
      this.filterBtns = jProps && jProps.eventFilterBtns.map(m => {
        m.active = JSON.stringify(this.$route.query) === JSON.stringify(m.query)
        return m
      })
    },
    initFilter () {
      this.filterItems = JSON.parse(JSON.stringify(this.baseFilters))
    },
    getList (filter) {
      this.loading = true
      this.overlay = !this.overlayShown && Object.keys(this.$route.query).length === 0 && this.theme.username === 'vbl'
      const query = `filter=${filter}`
      this.$VBL.tournament.getAllSummaries(query)
        .then((response) => {
          this.summaries = response.data.map(s => new Summary(s))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAds () {
      this.$http.get(`/ads?username=${this.subdomain}`)
        .then(r => {
          this.ads = r.data.map(m => JSON.parse(m)).filter(f => f.locations && f.locations.includes('events'))
        })
    },
    filterList (status) {
      var base = this.summaries.filter(f => f.isPublic || !this.publicView)
      if (this.username) return base.filter(t => t.dateStatus === status && t.organization.username === this.username)

      return this.summaries.filter(t => t.dateStatus === status)
    },
    toggleView () {
      this.listView = !this.listView
      this.$store.commit(mutations.SET_USER_PREFERENCE, { tournamentView: this.listView ? 'list' : 'card' })
    },
    onSearch: debounce(function () {
      this.saveState()
    }, 500),

    onPageChange (page) {
      this.page = page
      this.saveState()
    },
    saveState () {
      const query = {}
      const s = this.search
      Object.assign(query, this.$route.query, this.filterDto, this.page)
      if (s !== query.s) {
        if (s) {
          query.s = s
          delete query.p
        } else {
          delete query.s
        }
      }

      const keys = Object.keys(query)
      keys.forEach(k => {
        if (query[k] === '') delete query[k]
      })
      var url = this.$router.resolve({
        path: this.$route.path,
        query
      })
      this.currentUrl = url.href
      history.replaceState(
        {},
        null,
        url.href
      )
    },
    setState (q) {
      this.search = q ? q.s : null
      const e = q && q.xE ? q.xE.split(',') : []
      this.myFilter.eventTypes = this.eventTypes.filter(f => !e.includes(f))
      const sb = q && q.xSb ? q.xSb.split(',') : []
      this.myFilter.sBodies = this.sBodies.filter(f => !sb.includes(f))
      if (q && q.o) {
        this.myFilter.others = q.o.split(',')
      }
      if (q && q.u === 'false') this.myFilter.useCurrent = false
      if (q && q.sa) this.myFilter.searchAddress = this.location = q.sa
      if (q && q.sll) this.myFilter.searchLatLong = q.sll
      if (q && q.d) this.myFilter.maxDistance = +q.d
    },
    getLocation () {
      this.gError = null
      if (!('geolocation' in navigator)) {
        console.log('Geolocation is not available.')
        this.gError = 'Geolocation is not supported by this device'
        return
      }

      this.gettingLocation = true
      // get position
      navigator.geolocation.getCurrentPosition(pos => {
        this.gettingLocation = false
        this.geoPosition = pos
      }, err => {
        this.gettingLocation = false
        this.myFilter.useCurrent = false
        this.gError = 'Geolocation is not enabled. Please enable to use this feature'
        console.log(err.message)
      })
    },
    searchLocation (reverse) {
      this.gettingLocation = true
      this.$http.get(`Locations/GeoSearch?q=${this.location}`)
        .then(r => {
          if (r.data && r.data.length > 0) {
            this.location = this.myFilter.searchAddress = r.data[0].address
            this.myFilter.searchLatLong = r.data[0].latLong
          }
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => {
          this.gettingLocation = false
          if (this.myFilter.maxDistance > 500) this.myFilter.maxDistance = 50
        })
    },
    goto (i) {
      switch (i) {
        case 1:
          this.myFilter.eventTypes = ['Tournament', 'Series', 'League']
          break
        case 2:
          this.myFilter.eventTypes = ['Camp', 'Clinic', 'Tryout']
          break
      }
      this.overlay = false
    }
  },
  watch: {
    user: function (n, o) {
      if (n && !o) this.getList()
    },
    '$vuetify.breakpoint.xs': function (val) {
      if (val) {
        this.listView = false
      }
    },
    search: function (v) {
      this.onSearch()
    },
    myFilter: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.saveState()
        })
      }
    },
    'myFilter.useCurrent': function (n, o) {
      if (n === true && o === false) this.getLocation()
    },
    overlay: function (v) {
      if (v) this.overlayShown = true
    },
    '$route.query': 'mapFilterBtns'
  },
  mounted () {
    this.initFilter()
    if (this.preferences && this.preferences.tournamentView) {
      this.listView = this.preferences && this.preferences.tournamentView === 'list'
    } else {
      this.listView = !this.$vuetify.breakpoint.xs
    }
    this.setState(this.$route.query)
    this.mapFilterBtns()
  },
  beforeRouteUpdate (to, from, next) {
    const up = ['upcoming', 'today', 'ongoing', 'current']
    const a = up.includes(from.params.filter)
    const b = up.includes(to.params.filter)
    if (a !== b) {
      this.getList(to.params.filter)
    }
    const fq = from.query
    const tq = to.query
    const keys = Object.keys(fq)
    keys.forEach(k => {
      if (k !== 'p' && k !== 'ipp' && fq[k]) tq[k] = fq[k]
    })
    this.page = null
    this.setState(to.query)
    next()
  },
  created () {
    this.getAds()
    this.getList(this.$route.params.filter)
    this.newFilter && this.getLocation()
  },
  components: {
    HelpVideoDialog,
    AdCard
  }
}
</script>

<style>
</style>
